import { FETCH_GALLERY, GET_PHOTO } from '../actions/types';

const initialState = {
	photos: [],
	photo: {}
}

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_GALLERY:
			return {
				...state,
				photos: action.payload
			}
		case GET_PHOTO:
			return {
				...state,
				photo: action.payload
			}
		default:
			return state;
	}
}
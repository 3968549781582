import { FETCH_GALLERY, GET_PHOTO, URL } from './types';

export const fetchGallery = (loc, page) => dispatch => {
	fetch(URL + '/api/photos?location=' + loc + "&$skip=" + page)
		.then(res => res.json())
		.then(data => dispatch({
			type: FETCH_GALLERY,
			payload: data.data
		}));
}

export const setPhoto = (photo) => dispatch => {
	dispatch({
		type: GET_PHOTO,
		payload: photo
	})
}
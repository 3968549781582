import { combineReducers } from 'redux';
import mainReducer from './mainReducer';
import locReducer from './locReducer';
import calReducer from './calReducer';
import galleryReducer from './galleryReducer';

export default combineReducers({
	main: mainReducer,
	loc: locReducer,
	calendar: calReducer,
	gallery: galleryReducer
});
import React, { Component } from 'react'
import lang from '../../lang';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { fetchGallery } from '../../actions/galleryActions';
import $ from 'jquery'
import 'lightgallery';
import 'lg-thumbnail';
import Sound from 'react-sound';

import img_icon from '../../images/locations/Ikona01.png';
import img_sipka from '../../images/locations/galeria01.png';
import img_play from '../../images/locations/play.png';
import img_pause from '../../images/locations/pause.png';
import img_sound from '../../images/locations/IkonaSound.png';

import test_sound from '../../sounds/1_sk.mp3';

let sounds_en = [];
let sounds_sk = [];
let sounds_pl = [];

const sounds = []

for (let i = 0; i <= 30; i++) {
	if (i !== 0 && i !== 22 && i !== 24)
		sounds.push(i);
	else
		sounds.push(1);
}

const loadEntriesSk = (entries) =>
	Promise.all(entries.map((entry) => import(`../../sounds/${entry}_sk.mp3`)));

const loadEntriesEn = (entries) =>
	Promise.all(entries.map((entry) => import(`../../sounds/${entry}_en.mp3`)));

const loadEntriesPL = (entries) =>
	Promise.all(entries.map((entry) => import(`../../sounds/${entry}_pl.mp3`)));

loadEntriesSk(sounds)
	.then((entries) => { sounds_sk = entries });
loadEntriesEn(sounds)
	.then((entries) => { sounds_en = entries });
loadEntriesPL(sounds)
	.then((entries) => { sounds_pl = entries });

class LocationsInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaded_photos: [],
			pos: 0,
			gallery_width: 0,
			sound_state: Sound.status.STOPPED
		}
	}
	componentDidMount() {
		if ($('#lightgallery').data('lightGallery')) $('#lightgallery').data('lightGallery').destroy(true)
		$('#lightgallery').lightGallery()
	}

	componentDidUpdate() {
		if ($('#lightgallery').data('lightGallery')) $('#lightgallery').data('lightGallery').destroy(true)
		$('#lightgallery').lightGallery()
		setTimeout(() => {
			if (this.state.gallery_width !== $('#lightgallery').width()) {
				this.setState({ gallery_width: $('#lightgallery').width() });
			}
		}, 20);
	}

	componentWillUnmount() {
		if ($('#lightgallery').data('lightGallery')) $('#lightgallery').data('lightGallery').destroy(true)
	}
	changeAnim(smer) {
		if (smer !== 0) {
			//if (this.state.pos >= -500)
			//	this.setState({ pos: this.state.pos - speed });
			this.timerID = setInterval(
				() => this.tick(smer),
				1000 / 60
			);
		} else {
			clearInterval(this.timerID);
		}
	}
	tick(smer) {
		const speed = 7;
		if (smer === -1) {
			if (this.state.pos > $('#lightgalleryContainer').width() - $('#lightgallery').width())
				this.setState({ pos: this.state.pos - speed });
		} else if (smer === 1) {
			if (this.state.pos < 0)
				this.setState({ pos: this.state.pos + speed });
		}
	}
	playSound(play) {
		if (play)
			this.setState({ sound_state: Sound.status.PLAYING });
		else
			this.setState({ sound_state: Sound.status.PAUSED });
	}
	onSoundCompleted = () => {
		this.setState({ sound_state: Sound.status.PAUSED });
	}
	componentWillMount() {
		this.props.fetchGallery(this.props.location.number, 0);
	}
	componentWillReceiveProps(props) {
		if (props.photos) {
			let loaded_photos = props.photos;
			for (let i = 0; i < props.photos.length; i++) {
				fetch('https://presov.functugames.com/api/photos/' + props.photos[i]._id + '.jpg')//?width=320
					.then(res => res.blob())
					.then(data => {
						//let photo = props.photos[i];
						loaded_photos[i].image = URL.createObjectURL(data);
						loaded_photos[i].big = URL.createObjectURL(data);
						//this.loadBigImage(loaded_photos[i]._id, i);
						this.setState({
							loaded_photos: loaded_photos
						});
					});
			}
		}
	}
	loadBigImage(id, index) {
		fetch('https://presov.functugames.com/api/photos/' + id + '.jpg')
			.then(res => res.blob())
			.then(data => {
				let photo = this.state.loaded_photos[index];
				photo.big = URL.createObjectURL(data);
				this.onUpdateItem(index, photo)
				console.log(photo)
				//console.log(image);
				//return image;
			});
	}
	onUpdateItem = (i, newItem) => {
		this.setState(state => {
			const loaded_photos = state.loaded_photos.map((item, j) => {
				if (j === i) {
					return newItem;
				} else {
					return item;
				}
			});

			return {
				loaded_photos,
			};
		});
	};
	render() {
		let display_sipky = false;
		if ($('#lightgalleryContainer').width() < this.state.gallery_width)
			display_sipky = true;
		const locInfoStyle = {
			textAlign: 'left',
			paddingBottom: 30
		}
		const title1Style = {
			fontSize: '16px',
			fontWeight: '700',
			color: '#9E978A',
			marginLeft: '30px',
			position: 'relative',
			top: '0.2rem'
		}
		const line1Style = {
			transform: 'translateX(-50%)',
			marginLeft: '50%',
			marginTop: '-0.5rem',
			borderColor: '#EDEBE8',
			borderWidth: '2px',
			width: '100%',
			maxWidth: 1054,
			borderRadius: 2,
		}
		const line2Style = {
			transform: 'translateX(-50%)',
			marginLeft: '50%',
			marginTop: '-0.5rem',
			borderColor: '#EDEBE8',
			borderWidth: '2px',
			width: '100%',
			maxWidth: 627,
			borderRadius: 2,
		}
		const title2Style = {
			textIndent: '2rem',
			fontSize: '17px',
			paddingBottom: '1rem'
		}
		const locDescStyle = {
			fontSize: '14px',
			fontWeight: '400',
			color: '#7D776DFF',
			textIndent: '2rem'
		}
		const galleryStyle = {
			height: '250px',
			position: 'relative',
			overflow: 'hidden',
		}
		const galleryDivStyle = {
			width: 'max-content',
			position: 'absolute',
			top: 0,
			height: '100%',
			transform: `translateX(${this.state.pos}px)`
		}
		const sipkaLeftStyle = {
			backgroundImage: `url(${img_sipka})`,
			transform: 'translate(-50%, -50%) scaleX(-1)',
			position: 'absolute',
			top: '50%',
			display: display_sipky ? 'inline-flex' : 'none',
			width: 32,
			height: 32
		}
		const sipkaRightStyle = {
			backgroundImage: `url(${img_sipka})`,
			transform: 'translate(-50%, -50%)',
			position: 'absolute',
			top: '50%',
			display: display_sipky ? 'inline-flex' : 'none',
			width: 32,
			height: 32
		}
		const locPlayStyle = {
			backgroundImage: `url(${img_play})`,
			width: 48,
			height: 48,
			outline: 'none',
			border: 'none',
			display: this.state.sound_state === Sound.status.PAUSED || this.state.sound_state === Sound.status.STOPPED ? '' : 'none'
		}
		const locPauseStyle = {
			backgroundImage: `url(${img_pause})`,
			width: 48,
			height: 48,
			outline: 'none',
			border: 'none',
			display: this.state.sound_state === Sound.status.PLAYING ? '' : 'none'
		}
		const galleryItems = this.state.loaded_photos.map(photo => {
			if (photo.big) {
				return <a className="jg-entry" key={photo._id} href={photo.big} data-sub-html={'<span class=lg-popis-big>' + this.props.location.name + ' - </span><span class=lg-popis-small>' + photo.description + '</span>'}>
					<img className="img-responsive" src={photo.image} alt='' style={{ height: '250px', marginRight: '5px' }} />
				</a>
			}
			else if (photo.image) {
				return <a className="jg-entry" key={photo._id} href={photo.image} data-sub-html={"<h4>" + photo.description + "</h4>"}>
					<img className="img-responsive" src={photo.image} alt='' style={{ height: '250px', marginRight: '5px' }} />
				</a>
			}
			return null;
		});
		let sound_url = test_sound;
		if (this.props.location.number) {
			if (this.props.lang === 'sk')
				sound_url = sounds_sk[this.props.location.number].default;
			else if (this.props.lang === 'en')
				sound_url = sounds_en[this.props.location.number].default;
			else if (this.props.lang === 'pl')
				sound_url = sounds_pl[this.props.location.number].default;
		}
		return (
			<div style={locInfoStyle}>
				<img src={img_icon} alt=''></img>
				<span style={title1Style}>{lang[this.props.lang].locations.title}</span>
				<div className='row text-center'>
					<div className='col-12' style={{ padding: '0.6rem' }} >
						<hr style={line1Style}></hr>
					</div>
				</div>
				<div className='main-container' style={{ paddingLeft: '4rem', paddingRight: '4rem' }}>
					<h1 style={title2Style}>{this.props.location.name[this.props.lang.toUpperCase()]}</h1>
					<p style={locDescStyle} dangerouslySetInnerHTML={{ __html: this.props.location.description[this.props.lang.toUpperCase()] }} ></p>
					<div className='row text-center'>
						<div className='col-12' style={{ padding: '0.6rem' }}>
							<hr style={line2Style}></hr>
						</div>
					</div>
					<div id='audio-row' className='row' style={{ height: 62, marginTop: -40 }}>
						<div className='col-1 col-sm-1 col-md-2'></div>
						<div className='col-5 col-sm-5 col-md-4'>
							<div className='row'>
								<img src={img_sound} alt=''></img>
								<p style={{ marginTop: '13px' }}>{lang[this.props.lang].locations.audio}</p>
							</div>
						</div>
						<div className='col-5 col-sm-5 col-md-4 text-right'>
							<button className="btn img-btn" style={locPlayStyle} type="button" onClick={() => this.playSound(1)}></button>
							<button className="btn img-btn" style={locPauseStyle} type="button" onClick={() => this.playSound(0)}></button>
						</div>
						<div className='col-1 col-sm-1 col-md-2'></div>
					</div>
					<div className='row text-center'>
						<div className='col-12' style={{ padding: '0.6rem' }}>
							<hr style={line2Style}></hr>
						</div>
					</div>
				</div>
				<div className='row text-center' >
					<div className='col-1 col-sm-1 col-md-1'>
						<button className="btn img-btn" alt='' style={sipkaLeftStyle} onPointerDown={() => this.changeAnim(1)} onPointerUp={() => this.changeAnim(0)}></button>
					</div>
					<div id="lightgalleryContainer" className='col-10 col-sm-10 col-md-10' style={galleryStyle}>
						<div id="lightgallery" style={galleryDivStyle}>
							{galleryItems}
						</div>
					</div>
					<div className='col-1 col-sm-1 col-md-1'>
						<button className="btn img-btn" alt='' style={sipkaRightStyle} onPointerDown={() => this.changeAnim(-1)} onPointerUp={() => this.changeAnim(0)}></button>
					</div>
				</div>
				<Sound
					url={sound_url}
					playStatus={this.state.sound_state}
					onFinishedPlaying={this.onSoundCompleted}
				></Sound>
			</div>
		)
	}
}

LocationsInfo.propTypes = {
	lang: PropTypes.string.isRequired,
	photos: PropTypes.array,
	location: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	lang: state.main.lang,
	photos: state.gallery.photos,
	location: state.loc.location
});

export default connect(mapStateToProps, { fetchGallery })(LocationsInfo);

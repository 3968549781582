import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import './App.css';
import resize from './resize';

import Main from './components/pages/Main';
import Header from './components/layout/Header';
import MapContainer from './components/pages/Map';
import Locations from './components/pages/Locations';
import Calendar from './components/pages/Calendar';
import LocationsInfo from './components/pages/LocationsInfo';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
// import CalendarInfo from './components/pages/CalendarInfo';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

class App extends Component {
	updateDimensions = () => {
		resize('container');
	}
	componentDidMount() {
		window.addEventListener("resize", this.updateDimensions);
		this.updateDimensions();
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}
	render() {
		return (
			<Provider store={store}>
				<Router>
					<div className="Presov App" style={{ textAlign: 'center' }}>
						<Header></Header>
						<Navbar></Navbar>
						<div className="container">
							<Route exact path='/' component={Main} />
							<Route exact path='/lokacie' component={Locations} />
							<Route exact path='/kalendar' component={Calendar} />
							<Route exact path='/lokacie/info' component={LocationsInfo} />
							{/* <Route exact path='/kalendar/info' component={CalendarInfo} /> */}
							<Route exact path='/mapa' component={MapContainer} />
							<Route exact path='/privacy_sk' render={(props) => <PrivacyPolicy {...props} lang='sk' />} />
							<Route exact path='/privacy_en' render={(props) => <PrivacyPolicy {...props} lang='en' />} />
							<Route exact path='/privacy_pl' render={(props) => <PrivacyPolicy {...props} lang='pl' />} />
						</div>

						<Footer></Footer>
					</div>
				</Router>
			</Provider>
		);
	}
}

export default App;

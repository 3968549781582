import React, { Component } from 'react'
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import lang from '../../lang';
import { changeModal } from '../../actions/mainActions';
import Microlink from '@microlink/react';


import icon from '../../images/main/Ikona01.png';
import eu from '../../images/main/eu.png';
import presov from '../../images/main/presov.png';
import google from '../../images/main/google.png';
import apple from '../../images/main/apple.png';
import mobil from '../../images/main/mobil.png';
import main_img from '../../images/main/main.jpg';
import kraj_logo from '../../images/main/kraj_logo.png';

class Main extends Component {
	state = {
		modalLink: ''
	}
	openMap = () => {
		this.props.history.push("mapa");
	}
	openLocations = () => {
		this.props.history.push("lokacie");
	}
	openCalendar = () => {
		this.props.history.push("kalendar");
	}
	componentDidMount = () => {
		const ua = navigator.userAgent.toLowerCase();
		const isAndroid = ua.indexOf("android") > -1;
		const isiOS = this.iOS()
		//if (isAndroid) {
		//console.log(this.props.show)
		if (this.props.show) {
			this.props.changeModal(false);
			if (isAndroid) {
				this.setState({ modalLink: 'https://play.google.com/store/apps/details?id=com.functu.presovapp' });
				window.$("#installModal").modal();
			}
			if (isiOS) {
				this.setState({ modalLink: 'https://apps.apple.com/sk/app/dedi%C4%8Dstvo-pre%C5%A1ova/id1471969497' });
				window.$("#installModal").modal();
			}
		}
		//}
	}
	iOS() {
		var iDevices = [
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod'
		];
		if (!!navigator.platform) {
			while (iDevices.length) {
				if (navigator.platform === iDevices.pop()) { return true; }
			}
		}
		return false;
	}
	render() {
		const title1Style = {
			fontSize: '16px',
			fontWeight: '700',
			color: '#9E978A',
			marginLeft: '30px',
			position: 'relative',
			top: '0.2rem'
		}
		const line1Style = {
			transform: 'translateX(-50%)',
			marginLeft: '50%',
			marginTop: '-0.5rem',
			borderColor: '#EDEBE8',
			borderWidth: '2px',
			width: '100%',
			maxWidth: 1054,
			borderRadius: 2,
		}
		const line2Style = {
			transform: 'translateX(-50%)',
			marginLeft: '50%',
			marginTop: '-0.5rem',
			borderColor: '#EDEBE8',
			borderWidth: '2px',
			width: '100%',
			maxWidth: 627,
			borderRadius: 2,
		}
		const line3Style = {
			transform: 'translateX(-50%)',
			marginLeft: '50%',
			marginTop: '-0.5rem',
			borderColor: '#EDEBE8',
			borderWidth: '2px',
			width: '100%',
			maxWidth: 540,
			borderRadius: 2,
		}
		const text1Style = {
			fontSize: '16px',
			fontWeight: '400',
			color: '#7D776DFF',
			textIndent: '2rem'
		}
		const text2Style = {
			fontSize: '14px',
			fontWeight: '400',
			color: '#7D776DFF'
		}
		const text3Style = {
			fontSize: '12px',
			fontWeight: '400',
			color: '#7D776DFF',
		}
		const euStyle = {
			width: '100%',
			marginTop: '1.5rem'
		}
		return (
			<React.Fragment>
				<div style={mainStyle}>
					<img src={icon} alt=''></img>
					<span style={title1Style}>{lang[this.props.lang].main.home}</span>
					<div className='row text-center'>
						<div className='col-12' style={{ padding: '0.6rem' }} >
							<hr style={line1Style}></hr>
						</div>
					</div>
					<div className='main-container' style={{ paddingLeft: '4rem', paddingRight: '4rem' }}>
						<div>
							<p style={text1Style} dangerouslySetInnerHTML={{ __html: lang[this.props.lang].main.text }}></p>
						</div>
						<div className='row text-center'>
							<div className='col-12'>
								<img src={main_img} style={{ width: '100%' }}></img>
							</div>
						</div>
						<div className='row text-center'>
							<div className='col-12' style={text2Style}>
								{lang[this.props.lang].main.text2}
							</div>
						</div>
						<div className='row text-center mt-3'>
							<div className='col-xs-7 col-sm-4 col-md-4 px-5 px-sm-0 pb-1'>
								<a href="https://sk.plsk.eu/domovska-stranka">
									<img src={eu} alt='' style={{ width: '90%' }}></img>
								</a>
							</div>
							<div className='col-xs-7 col-sm-4 col-md-4 px-5 px-sm-0 pb-4'>
								<a href="https://www.po-kraj.sk/sk/">
									<img src={kraj_logo} alt='' style={{ width: '70%', paddingTop: '0.55rem' }}></img>
								</a>
							</div>
							<div className='col-xs-7 col-sm-4 col-md-4 px-5 px-sm-0 pb-4'>
								<a href="https://www.presov.sk/">
									<img src={presov} alt='' style={{ width: '30%', paddingRight: '1rem' }} ></img>
								</a>
								<span id='presov-logo-text' style={text2Style} className='font-weight-bold'>{lang[this.props.lang].main.mesto.toUpperCase()}</span>
							</div>
						</div>
						<div className='row text-center mt-3'>
							<div className='col-12' style={{ padding: '0.6rem' }}>
								<hr style={line2Style}></hr>
							</div>
						</div>
						<div className='row text-left' style={{ marginTop: '-1rem' }}>
							<div className='col-xs-12 col-sm-3 col-md-3'>
								<span style={text2Style}>{lang[this.props.lang].main.download}</span>
							</div>
							<div className='col-xs-12 col-sm-6 col-md-6' style={{ lineHeight: 1 }}>
								<div className='row text-left'>
									<div className='col-6 col-sm-6 col-md-6'>
										<a href="https://play.google.com/store/apps/details?id=com.functu.presovapp">
											<img className='logo-store' src={google} alt='' style={{ width: 200 }}></img>
										</a>
									</div>
									<div className='col-6 col-sm-6 col-md-6'>
										<span className='download-text' style={text3Style} dangerouslySetInnerHTML={{ __html: lang[this.props.lang].main.android }}></span>
									</div>
								</div>
								<div className='row text-center'>
									<div className='col-12' style={{ padding: '0.6rem' }}>
										<hr style={line3Style}></hr>
									</div>
								</div>
								<div className='row text-left' style={{ marginTop: '-1rem' }}>
									<div className='col-6 col-sm-6 col-md-6'>
										<a href="https://apps.apple.com/sk/app/dedi%C4%8Dstvo-pre%C5%A1ova/id1471969497">
											<img className='logo-store' src={apple} alt='' style={{ width: 200 }}></img>
										</a>
									</div>
									<div className='col-6 col-sm-6 col-md-6'>
										<span className='download-text' style={text3Style} dangerouslySetInnerHTML={{ __html: lang[this.props.lang].main.ios }}></span>
									</div>
								</div>
							</div>
							<div className='col-xs-12 col-sm-3 col-md-3 text-center'>
								<img id='mobil' src={mobil} alt='' style={{ marginTop: '3rem' }}></img>
							</div>
						</div>
					</div>
					<div className="modal fade" id="installModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLabel">{lang[this.props.lang].main.download}</h5>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<Microlink url={this.state.modalLink} />
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary" data-dismiss="modal">{lang[this.props.lang].main.close}</button>
									<a href={this.state.modalLink} className="btn btn-primary">{lang[this.props.lang].main.downloadBtn}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

Main.propTypes = {
	lang: PropTypes.string.isRequired,
	show: PropTypes.bool.isRequired,
	changeModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	lang: state.main.lang,
	show: state.main.showModal,
});

const mainStyle = {
	textAlign: 'left',
	//width: '100%'
}

export default connect(mapStateToProps, { changeModal })(Main);

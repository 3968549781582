import React, { Component } from 'react'
import { PropTypes } from 'prop-types';
import lang from '../../lang';
import { connect } from 'react-redux';
import { fetchCalendar, fetchHolidays } from '../../actions/calActions';
import moment from 'moment';

import CalendarItem from '../CalendarItem';

import img_icon from '../../images/calendar/Ikona01.png';
import img_line1 from '../../images/main/line04.png';

class Calendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			events: [],
			selectDate: moment(),
			init: true
		}
	}
	updateCalendar = (month, year) => {
		let monthStr = "01";
		if (month + 1 > 9)
			monthStr = month + 1;
		else
			monthStr = "0" + (month + 1);
		this.props.fetchHolidays(monthStr, year);
		this.props.fetchCalendar(monthStr, year);
	}
	processEvents(date) {
		const calendar = this.props.calendar;
		let events = [];
		for (let i = 0; i < calendar.length; i++) {
			let event = {};
			let day_num = 0;
			let day_num_end = 0;
			if (calendar[i].start.date) {
				let cal_date = new Date(calendar[i].start.date);
				let cal_date_end = new Date(calendar[i].end.date);
				day_num = cal_date.getDate();
				day_num_end = cal_date_end.getDate() - 1;
				if (date.month() < cal_date.getMonth() && date.year() === cal_date.getFullYear()) {
					continue;
				}
				if (date.month() !== cal_date.getMonth()) {
					day_num = 1;
				}
				if (date.month() !== cal_date_end.getMonth()) {
					day_num_end = 31;
				}
			}
			else if (calendar[i].start.dateTime) {
				day_num = new Date(calendar[i].start.dateTime).getDate();
				day_num_end = new Date(calendar[i].end.dateTime).getDate();
			}
			if (date.date() >= day_num && date.date() <= day_num_end) {
				event.name = calendar[i].summary || "";
				event.description = calendar[i].description || "";
				event.location = calendar[i].location || "";
				event.id = calendar[i].id;
				events.push(event);
			}
		}
		this.setState({ events: events, selectDate: date });
	}
	isEvents = (date) => {
		const calendar = this.props.calendar;
		for (let i = 0; i < calendar.length; i++) {
			let day_num = 0;
			let day_num_end = 0;
			if (calendar[i].start.date) {
				let cal_date = new Date(calendar[i].start.date);
				let cal_date_end = new Date(calendar[i].end.date);
				day_num = cal_date.getDate();
				day_num_end = cal_date_end.getDate() - 1;
				if (date.month() < cal_date.getMonth() && date.year() === cal_date.getFullYear()) {
					continue;
				}
				if (date.month() !== cal_date.getMonth()) {
					day_num = 1;
				}
				if (date.month() !== cal_date_end.getMonth()) {
					day_num_end = 31;
				}
			}
			else if (calendar[i].start.dateTime) {
				day_num = new Date(calendar[i].start.dateTime).getDate();
				day_num_end = new Date(calendar[i].end.dateTime).getDate();
			}
			if (date.date() >= day_num && date.date() <= day_num_end) {
				return true;
			}
		}
		return false;
	}
	processHolidays(calItems) {
		for (let i = 0; i < this.props.holidays.length; i++) {
			let dayNumb = new Date(this.props.holidays[i].start.date).getDate();
			calItems[dayNumb - 1].fiesta = true;
		}
		return calItems;
	}
	onSelect = (date, previousDate, currentMonth) => {
		if (currentMonth.isSame(date, 'month')) {
			this.processEvents(date);
			return true;
		}
	}
	componentDidUpdate = () => {
		if (this.state.init) {
			if (this.props.calendar.length > 0) {
				this.processEvents(this.state.selectDate);
				this.setState({ init: false });
			}
		}
	}
	render() {
		const title1Style = {
			fontSize: '16px',
			fontWeight: '700',
			color: '#9e978a',
			marginLeft: '30px',
			position: 'relative',
			top: '0.2rem'
		}
		const line1Style = {
			marginTop: '-30px'
		}
		let monthStyle = {
			backgroundColor: '#ECEBE8',
			width: '100%',
			maxWidth: '816px',
			height: '45px',
			borderRadius: '15px',
			color: '#7c776c',
			transform: 'translateX(-50%)',
			marginLeft: '50%',
			textAlign: 'left',
			marginTop: '1.5rem',
			marginBottom: '1rem'
		}
		const eventStyle = {
			color: '#66638f',
			fontSize: 14,
			textAlign: 'left',
			fontStyle: 'italic',
			lineHeight: 1,
			marginLeft: '1rem'
		}
		let dayClasses = (date) => {
			for (let i = 0; i < this.props.holidays.length; i++) {
				let dayNumb = new Date(this.props.holidays[i].start.date).getDate();
				let day_m = date.date();
				if (day_m === dayNumb)
					return ['holidays']
			}
			let day = date.isoWeekday();
			if (day === 6 || day === 7) {
				return ['weekend'];
			}
			return ['normal'];
		};
		const capitalize = (s) => {
			if (typeof s !== 'string') return ''
			return s.charAt(0).toUpperCase() + s.slice(1)
		}
		const events = this.state.events.map(event => (
			<li key={event.id} style={eventStyle}>
				<p style={{ fontWeight: '700' }}>{event.name}</p>
				<br />
				<p dangerouslySetInnerHTML={{ __html: event.description }}></p>
				<br />
				<p>{event.location}</p>
				<hr style={{ borderColor: '#66638f', borderWidth: '2px' }}></hr>
			</li>
		));
		let holiday = false;
		for (let i = 0; i < this.props.holidays.length; i++) {
			let dayNumb = new Date(this.props.holidays[i].start.date).getDate();
			let day_m = this.state.selectDate.date();
			if (day_m === dayNumb) {
				holiday = true;
				break;
			}
		}
		if (moment().isSame(this.state.selectDate, 'day')) {
			monthStyle.backgroundColor = '#7980CC';
			monthStyle.color = '#fff';
		} else if (holiday) {
			monthStyle.backgroundColor = '#A0DBD2';
			monthStyle.color = '#66638f';
		} else if (this.state.selectDate.isoWeekday() > 5) {
			monthStyle.backgroundColor = '#BBBEEB';
			monthStyle.color = '#7c776c';
		} else {
			monthStyle.backgroundColor = '#ECEBE8';
			monthStyle.color = '#7c776c';
		}
		return (
			<div style={calStyle}>
				<div className='row'>
					<div className='col-12 col-sm-12 col-md-4 col-lg-3' style={{ marginTop: '-1.5rem' }}>
						<div style={{ textAlign: 'left' }}>
							<img src={img_icon} alt=''></img>
							<span style={title1Style}>{lang[this.props.lang].calendar.title}</span>
						</div>
						<img src={img_line1} alt='' style={line1Style}></img>
						<CalendarItem
							onSelect={this.onSelect}
							dayClasses={dayClasses}
							updateCalendar={this.updateCalendar}
							locale="sk"
							startOfWeekIndex={1}
							isEvents={this.isEvents}
						></CalendarItem>
					</div>
					<div className='col-12 col-sm-12 col-md-8 col-lg-9'>
						<div style={monthStyle}>
							<div className='row' style={{ paddingLeft: '1rem', paddingRight: '1rem', height: '100%' }}>
								<div className='col-5 col-sm-5 col-md-5'>
									<span style={{ position: 'absolute', transform: 'translateY(-50%)', top: '45%' }}>{this.state.selectDate.format("DD/MM/YY")}</span>
								</div>
								<div className='col-7 col-sm-7 col-md-7'>
									<span style={{ position: 'absolute', transform: 'translateY(-50%)', top: '45%' }}>{capitalize(this.state.selectDate.format('dddd'))}</span>
								</div>
							</div>
						</div>
						<ul>
							{events}
						</ul>
					</div>
				</div>
			</div>
		)
	}
}

Calendar.propTypes = {
	lang: PropTypes.string.isRequired,
	calendar: PropTypes.array.isRequired,
	holidays: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
	lang: state.main.lang,
	calendar: state.calendar.calendar,
	holidays: state.calendar.holidays
});

const calStyle = {
	textAlign: 'center'
}

export default connect(mapStateToProps, { fetchCalendar, fetchHolidays })(Calendar)


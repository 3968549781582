import React, { Component } from 'react'

class PrivacyPolicy extends Component {
	renderPrivacySK() {
		return (
			<div>
				<h2 style={{ textAlign: 'center' }}>Zásady ochrany osobných údajov</h2>
				<p>&nbsp;</p>
				<p>Spoločnosť Andrascik s.r.o. rešpektuje práva užívateľov aplikácie vyvinutej na ochranu súkromia a prehlasuje, že vynakladá všetko úsilie na to, že nebude zbierať údaje, s výnimkou tých, ktoré sú nevyhnutné pre správne fungovanie aplikácie alebo tých ktorých zber má zvýšiť užitočnosť produktov firmy.</p>
				<p>1. Žiadne údaje, ktoré by akokoľvek umožňovali identifikáciu používateľa, nie sú zberané ani sprístupnené poskytovateľom služieb žiadnej tretej strane.</p>
				<p>2. Andrascik s.r.o. informuje o tom, že pri používaní aplikácie sa do zariadenia so systémom Android a iOS uložia iba dočasné údaje na užívateľovom zariadení.</p>
				<p>3. Aplikácia anonymne zhromažďuje štatistické údaje okrem iného umiestnenie používateľské alebo údaje poskytované systémami Android a iOS. V aplikácii sa používa na zhromažďovanie štatistických údajov a spôsob, akým ich aplikácia používa:</p>
				<p>a) službu Google Analytics vo vlastníctve spoločnosti Google Inc.&nbsp;<a href="https://policies.google.com/privacy?hl">Zásady ochrany osobných údajov služby Google Analytics.</a></p>
				<p>b) b) službu Facebook.&nbsp;<a href="https://www.facebook.com/privacy/explanation">Zásady ochrany osobných údajov Facebook.</a></p>
				<p>4. V aplikácii sa používajú základy máp externej služby API Google Maps.&nbsp;<a href="https://policies.google.com/privacy?hl">Zásady ochrany osobných údajov spoločnosti Google.</a></p>
				<p>&nbsp;</p>
				<p>5. Aplikácie pre systém Android / iOS používajú nasledujúce oprávnenia:</p>
				<ul>
					<li>Geolokácia - umožňuje zobrazenie polohy používateľa na mapách, nastavenie POI na ich pozíciu, navigáciu po trase...</li>
					<li>Pamäť - karta SD - vďaka tomuto povoleniu môže aplikácia uložiť dáta do pamäte zariadenia, takže je možné používať mnoho funkcií offline</li>
					<li>Úplný prístup k sieti - vo väčšine prípadov potrebný na aktualizáciu údajov a zobrazenie niektorých údajov pri prehliadaní online. Okrem toho v tomto režime fungujú služby Google Analytics, ktoré nám umožňujú anonymne sledovať správanie používateľa a službu Crashlytics, ktorá informuje o možných chybách aplikácie</li>
					<li>Komunikácia so službami Google (služby Google Play) - používame ho na správne fungovanie geolokácie, pozadia mapy, Google Analytics a schopnosť priamo komunikovať s aplikáciami Google, ako sú Mapy Google alebo Gmail</li>
					<li>Možnosť vyvolať službu vytáčania - vďaka tomuto povoleniu môže používateľ kliknúť na telefónne číslo obsiahnuté v obsahu aplikácie, aby ho mohol okamžite vytočiť</li>
					<li>Prístup k fotoaparátu - povolenie potrebné pre funkcionalitu, ktorá vyžaduje fotografovanie, skenery QR kódov / čiarových kódov a funkcie AR (rozšírená realita)</li>
					<li>Prístup do fotogalérie - používa sa iba funkcionalita, ktorá umožňuje odovzdať fotografiu do databázy aplikácií alebo vytvoriť fotorámček</li>
					<li>Aplikácia zhromažďuje anonymne ID používateľa na odosielanie prispôsobených upozornení. Údaje sa spracovávajú na serveri, ale nezhromažďujeme žiadne údaje, ktoré nám umožňujú identifikovať používateľa, iba jeho zariadenie</li>
				</ul>
				<p>Fotografie nahrané používateľmi do databázy aplikácií nemôžu porušovať zákon o autorských právach a súvisiacich právach.</p>
				<p>Materiál, ktorý porušuje zákon o autorských právach a súvisiacich právach, bude odstránený.</p>
				<p>Vlastníci databázy nie sú zodpovední za zaslané materiály, iba používatelia sú výlučne zodpovední.</p>
				<p>Ak máte akékoľvek otázky alebo nejasnosti, radi Vám pomôžeme. Kontaktujte nás na adrese <a href="mailto:webmaster@presov.sk">webmaster@presov.sk</a></p>
				<p>Nariadenia nadobúdajú účinnosť 1. mája 2018.</p>
			</div>
		);
	}
	renderPrivacyEN() {
		return (
			<div>
				<h2 style={{ textAlign: 'center' }}>Privacy Policy</h2>
				<p>&nbsp;</p>
				<p>The company Andrascik s.r.o. respects the right of users of applications created by it for privacy and declares that they make every effort not to collect any data except those necessary for the proper functioning of the application or those whose collection is intended to increase the usability of the company's products.</p>
				<p>1. Any data that could in any way allow identification of the User, are not collected by the application or made available by the Service Provider to any third parties.</p>
				<p>2. Andrascik s.r.o. informs that when using the application from an Android or iOS device, only temporary data are saved on the user's device</p>
				<p>3. The application anonymously collects statistical data, among others, user's location or data provided by Android and iOS. The following services in the application are responsible for collecting statistical data and the way the application is used:</p>
				<p>a) Google Analytics service owned by Google inc.&nbsp;<a href="https://policies.google.com/privacy?hl">The Google Analytics privacy policy.</a></p>
				<p>b) Facebook service.&nbsp;<a href="https://www.facebook.com/privacy/explanation">Facebook privacy policy.</a></p>
				<p>4. Map foundations in the application use the external Google Maps API service.&nbsp;<a href="https://policies.google.com/privacy?hl">Google inc privacy policy.</a></p>
				<p>&nbsp;</p>
				<p>5. Applications for Android / iOS system use the following permissions:</p>
				<ul>
					<li>Location - allows displaying the user's position on the maps, adjusting the results of the POI to its position, navigating the route...</li>
					<li>Memory - SD card - thanks to this permission, the application can save data in the device's memory so that the use of many functionalities is possible offline</li>
					<li>Full network access - in most cases it is required to update data and display some data while browsing, online. Additionally, in this mode, Google Analytics services operate, which enable us to anonymously monitor user behavior and Crashlytics service informing us about potential application errors</li>
					<li>Communication with Google Services (Google Play Services) - we use it for the correct operation of geolocation, background maps, Google Analytics and the ability to directly communicate with Google applications such as Google Maps or Gmail</li>
					<li>Possibility to call the dialing service - thanks to this permission, the user can click on the phone number contained in the contents of the application so that he will be available to immediately make a call</li>
					<li>Access to the camera - permission needed for functionality that requires taking a picture, QR code / bar code scanners and AR functionality (augmented reality)</li>
					<li>Access to the Photo Gallery - used only in the functionality that gives the opportunity to upload a photo to the application's database or make a photo frame</li>
					<li>The application collects anonymously user device's IDs to send personalized notifications. The data is processed on the server, but we do not collect any data that allows us to identify the user, only his device</li>
				</ul>
				<p>Photos uploaded to the application's database by users can not violate the Copyright and Related Rights Act.</p>
				<p>Photos that violates the Copyright and Related Rights Act will be removed.</p>
				<p>The owners of the database are not responsible for the uploaded content by users, and the users are solely responsible.</p>
				<p>If you have any questions or ambiguities, we are happy to help. Contact us at <a href="mailto:webmaster@presov.sk">webmaster@presov.sk</a></p>
				<p>The regulations enter into force on May 1, 2018.</p>
			</div>
		);
	}
	renderPrivacyPL() {
		return (
			<div>
				<h2 style={{ textAlign: 'center' }}>Polityka prywatności aplikacji mobilnych</h2>
				<p>&nbsp;</p>
				<p>Firma Andrascik s.r.o. szanuje prawo użytkowników aplikacji przez nią stworzonych  do prywatności i oświadcza, że dokłada wszelkich starań, aby nie gromadzić żadnych danych oprócz tych, które są konieczne do prawidłowego funkcjonowania aplikacji lub tych, których gromadzenie ma na celu zwiększenie użyteczności produktów firmy.</p>
				<p>1. Wszelkie dane, które mogłyby w jakikolwiek sposób pozwolić na identyfikacje (ustalenie tożsamości) Użytkownika, nie są gromadzone przez aplikację ani udostępniane przez Usługodawcę jakimkolwiek osobom trzecim.</p>
				<p>2. Andrascik s.r.o. informuje, iż podczas użytkowania aplikacji z poziomu urządzenia z systemem Android i iOS zapisywane są tylko tymczasowe dane na urządzeniu klienta.</p>
				<p>3. Aplikacja anonimowo zbiera dane statystyczne dotyczące m.in. lokalizacji użytkownika  czy danych udostępnianych przez system Android i iOS. W aplikacji za zbieranie danych statystycznych oraz sposobu użytkowania aplikacji odpowiedzialne są następujące usługi:</p>
				<p>a) usługa Google Analytics, której właścicielem jest firma Google inc.&nbsp;<a href="https://policies.google.com/privacy?hl">Polityka bezpieczeństwa usługi Google Analytics.</a></p>
				<p>b) usługa firmy Facebook.&nbsp;<a href="https://www.facebook.com/privacy/explanation">Polityka prywatności Facebook.</a></p>
				<p>4. W aplikacji podkłady mapowe korzystają z zewnętrznej usługi Google Maps API.&nbsp;<a href="https://policies.google.com/privacy?hl">Polityka prywatności Google inc.</a></p>
				<p>&nbsp;</p>
				<p>5. Aplikacje na system Android/iOS korzysta z następujących uprawnień:</p>
				<ul>
					<li>Lokalizacja – umożliwia wyświetlenie pozycji użytkownika na mapach, dostosowania wyników punktów POI do jego pozycji, nawigowania względem trasy...</li>
					<li>Pamięć - karta SD – dzięki temu uprawnieniu aplikacja może zapisywać dane w pamięci urządzenia dzięki czemu użycie wielu funkcjonalności jest możliwe w trybie offline,</li>
					<li>Pełny dostęp do sieci – w większości przypadków wymagany do aktualizacji danych oraz wyświetlania części danych podczas przeglądania, w trybie online. Dodatkowo w tym trybie działają usługi Google Analytics, które umożliwiają nam anonimowe monitorowanie zachowań użytkownika oraz usługa Crashlytics informująca o ewentualnych błędach aplikacji</li>
					<li>Komunikacja z Usługami Google (Google Play Services) – wykorzystujemy ją do poprawnego działania geolokalizacji, podkładów mapowych, usługi Google Analytics oraz możliwości bezpośredniego komunikowania się z aplikacjami Google takim jak Google Maps czy Gmail</li>
					<li>Możliwość wywołania usługi wybierania numeru – dzięki temu uprawnieniu użytkownik może kliknąć w numer telefonu zawarty w treściach aplikacji dzięki czemu zostanie wywołana od razu możliwości zadzwonienia do niego</li>
					<li>Dostęp do aparatu – uprawnienie potrzebne do funkcjonalności, które wymagają zrobienie zdjęcia, do skanerów kodów QR/kreskowych oraz w funkcjonalności AR (poszerzonej rzeczywistości)</li>
					<li>Dostęp do Galerii Zdjęć – wykorzystywany tylko w funkcjonalności, które dają możliwość wgrać zdjęcie do bazy danych aplikacji lub zrobić foto ramkę</li>
					<li>Aplikacja zbiera anonimowo identyfikatory urządzeń użytkowników w celu wysyłania personalizowanych powiadomień np. dotyczących lotu lub ofert. Dane są przetwarzane na serwerze, ale nie gromadzimy żadnej danej pozwalającej zidentyfikować nam danego użytkowika, a jedynie jego urządzenie</li>
				</ul>
				<p>Zdjęcia przesłane do bazy danych aplikacji przez użytkowników nie mogą naruszać Ustawy o prawie autorskim i prawach pokrewnych.</p>
				<p>Materiał, który narusza Ustawę o prawie autorskim i prawach pokrewnych, będzie usuwany.</p>
				<p>Właściciele bazy danych nie ponoszą odpowiedzialności za przesłane materiały, a odpowiedzialność ponoszą wyłącznie użytkownicy.</p>
				<p>W razie jakichkolwiek pytań lub niejasności chętnie służymy pomocą. Skontaktuj się z nami na adres <a href="mailto:webmaster@presov.sk">webmaster@presov.sk</a></p>
				<p>Regulamin wchodzi w życie 1 maja 2018 r.</p>
			</div>
		);
	}
	render() {
		let privacy;
		if (this.props.lang === 'sk')
			privacy = this.renderPrivacySK()
		else if (this.props.lang === 'en')
			privacy = this.renderPrivacyEN()
		else
			privacy = this.renderPrivacyPL()
		return (
			<React.Fragment>
				<div style={{ textAlign: 'left', paddingTop: '1rem' }}>
					{privacy}
				</div>
			</React.Fragment>
		)
	}
}

export default PrivacyPolicy;

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import resize from '../../resize';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import lang from '../../lang';
import { changeTab } from '../../actions/mainActions';

import img_icon_home from '../../images/main/A2.png';
import img_icon_map from '../../images/main/B1.png';
import img_icon_loc from '../../images/main/B3.png';
import img_icon_cal from '../../images/main/B4.png';
import line1 from '../../images/main/znacka01.png';
import line2 from '../../images/main/znacka02.png';
import line3 from '../../images/main/znacka03.png';
import line4 from '../../images/main/znacka04.png';

const line_colors = ['#E00000', '#7CDED5', '#FAD26E', '#BCBFEB']

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tab: 0
		}
	}
	lines = [line1, line3, line2, line4];
	openHome = () => {
		this.setState({ tab: 0 });
	}
	openMap = () => {
		this.setState({ tab: 1 });
	}
	openLocations = () => {
		this.setState({ tab: 2 });
	}
	openCalendar = () => {
		this.setState({ tab: 3 });
	}
	render() {
		const textStyle = {
			position: 'absolute',
			transform: 'translate(-50%, -50%)',
			left: '55%',
			top: '50%',
		}
		const iconStyle = {
			position: 'absolute',
			transform: 'translate(0%,-50%)',
			left: '0%',
			top: '50%'
		}
		const linkStyle = {
			position: 'absolute',
			width: '100%',
			height: '100%',
			transform: 'translate(-50%,-50%)',
			top: '50%',
			left: '50%',
			color: '#7D776D',
			fontWeight: '700',
			fontSize: '100%',
			lineHeight: 1
		}
		const homeStyle = {
			backgroundColor: '#ecebe8',
			borderRadius: '15px 0 0 15px',
			width: '300px',
			height: '44px',
			position: 'relative'
		}
		const mapStyle = {
			backgroundColor: '#7bded4',
			width: '300px',
			height: '44px',
			position: 'relative'
		}
		const locStyle = {
			backgroundColor: '#f9d26d',
			width: '300px',
			height: '44px',
			position: 'relative'
		}
		const calStyle = {
			backgroundColor: '#bbbeeb',
			borderRadius: '0 15px 15px 0',
			width: '300px',
			height: '44px',
			position: 'relative'
		}
		const lineStyle = {
			marginTop: '-0.5rem',
			width: '100%',
			maxWidth: 1054,
			borderRadius: 2,
			borderColor: line_colors[this.state.tab],
			borderWidth: '4px'
		}
		return (
			<React.Fragment>
				<div className='container'>
					<div className='row text-center'>
						<div className='col-12' style={{ zIndex: -1 }}>
							<hr style={lineStyle}></hr>
						</div>
					</div>
				</div>
				<nav className='navbar navbar-expand-sm navbar-light bg-transparent sticky-top' style={{ marginTop: '-2rem' }}>
					<div className='container'>
						<div className='row text-center'>
							<div className='col-3' style={homeStyle}>
								<Link className='nav-link' to='/' onClick={this.openHome} style={linkStyle}>
									<span className='nav-link-text' style={textStyle}>{lang[this.props.lang].main.home}</span>
									<span className='nav-link-icon' style={iconStyle}><img src={img_icon_home} alt=""></img></span>
								</Link>
							</div>
							<div className='col-3' style={mapStyle}>
								<Link className='nav-link' to='/mapa' onClick={this.openMap} style={linkStyle}>
									<span className='nav-link-text' style={textStyle}>{lang[this.props.lang].main.map}</span>
									<span className='nav-link-icon' style={iconStyle}><img src={img_icon_map} alt=""></img></span>
								</Link>
							</div>
							<div className='col-3' style={locStyle}>
								<Link className='nav-link' to='/lokacie' onClick={this.openLocations} style={linkStyle}>
									<span className='nav-link-text' style={textStyle}>{lang[this.props.lang].main.loc}</span>
									<span className='nav-link-icon' style={iconStyle}><img src={img_icon_loc} alt=""></img></span>
								</Link>
							</div>
							<div className='col-3' style={calStyle}>
								<Link className='nav-link' to='/kalendar' onClick={this.openCalendar} style={linkStyle}>
									<span className='nav-link-text' style={textStyle}>{lang[this.props.lang].main.cal}</span>
									<span className='nav-link-icon' style={iconStyle}><img src={img_icon_cal} alt=""></img></span>
								</Link>
							</div>
						</div>
					</div>
				</nav>
			</React.Fragment>
		)
	}
}

Navbar.propTypes = {
	lang: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
	lang: state.main.lang
});

export default connect(mapStateToProps, { changeTab })(Navbar);
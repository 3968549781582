export default function index(el_name) {
	//console.log(el_name);
	let el = document.getElementById(el_name);
	if (el) {
		let w = window.innerWidth
		//let h = window.innerHeight
		let wDefault = 1920
		//let hDefault = 1920
		let ratio = 1
		let style = window.getComputedStyle(el)
		let scale_stage = w / wDefault
		ratio = scale_stage;
		if (ratio < 0.5)
			ratio = 0.5;

		if (!el.scaledCache) el.scaledCache = style.transform
		el.style.transform = ' scale(' + ratio + ')'
		//el.style.marginLeft = 

		//console.log('Global ratio:', ratio)
	}
}

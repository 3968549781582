import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { connect } from 'react-redux';
import { fetchLocations, setLocation, fetchMapPhotos } from '../../actions/locActions';
import lang from '../../lang';
import $ from 'jquery'

import img_marker from '../../images/map/MapMarker.png';
import img_marker2 from '../../images/map/MapMarker2.png';
import icon from '../../images/map/Ikona01.png';

class MapContainer extends Component {
	state = {
		showingInfoWindow: false,
		activeMarker: {},
		selectedPlace: {},
		photo: '',
		photoImg: null,
	};
	componentWillMount() {
		this.props.fetchLocations();
		this.props.fetchMapPhotos();
	}
	onMarkerClick = (props, marker, e) => {
		this.setState({ photoImg: null });
		setTimeout(() => {
			props.google.maps.event.addDomListener($('#info')[0], 'click', this.onInfoWindowClick);
		}, 100);
		this.setState({
			selectedPlace: props,
			activeMarker: marker,
			showingInfoWindow: true
		});
		this.getMapPhoto(props);
	}
	getMapPhoto = (loc) => {
		fetch('https://presov.functugames.com/api/photos/' + this.getLocPhoto(loc.location.number) + '.jpg?width=150')
			.then(res => res.blob())
			.then(data => {
				let photo = URL.createObjectURL(data);
				/*if ($('#infoImg').length > 0) {
					$('#infoImg').attr('src', photo);
				}*/
				this.setState({ photoImg: photo });
				console.log($('#infoImg'))
				//console.log(image);
				//return image;
			});
	}
	getLocPhoto = (loc) => {
		console.log('getLocPhoto: ' + loc)
		for (let i = 0; i < this.props.locations.length; i++) {
			if (loc === this.props.locations[i].number) {
				console.log(this.props.photos[i]._id);
				return this.props.photos[i]._id;
			}
		}
	}
	onInfoWindowOpen = () => {
		console.log('Open')
		//const button = (<button onClick={e => { console.log("hmapbuttoni1"); }}>mapbutton</button>);
		//ReactDOM.render(React.Children.only(button), document.getElementById("iwc"));
	}
	onMapClicked = (props) => {
		if (this.state.showingInfoWindow) {
			this.setState({
				showingInfoWindow: false,
				activeMarker: null
			})
		}
	};
	onInfoWindowClose = () => {
		if (this.state.showingInfoWindow) {
			this.setState({
				showingInfoWindow: false,
				activeMarker: null
			})
		}
	}
	onInfoWindowClick = () => {
		this.props.setLocation(this.state.selectedPlace.location);
		this.props.history.push("lokacie/info");
	}
	render() {
		const locTitleStyle = {
			fontSize: '14px',
			color: 'black',
			width: '200px',
			marginTop: '1rem'
		}
		const mapStyle = {
			transform: 'translateX(-50%)',
			left: '50%',
			position: 'absolute',
			width: '100%',
			maxWidth: '1080px',
			height: '650px',
			minHeight: '650px',
			textAlign: 'left'
		}
		const title1Style = {
			fontSize: '16px',
			fontWeight: '700',
			color: '#9E978A',
			marginLeft: '30px',
			position: 'relative',
			top: '0.2rem'
		}
		const line1Style = {
			transform: 'translateX(-50%)',
			marginLeft: '50%',
			marginTop: '-0.5rem',
			borderColor: '#EDEBE8',
			borderWidth: '2px',
			width: '96%',
			maxWidth: 1054,
			borderRadius: 2,
		}
		const infoBtnStyle = {
			width: '100%',
			height: '100%',
			position: 'absolute',
			background: 'transparent',
			border: 'none',
			cursor: 'pointer',
			outline: 'none',
		}
		const mapMarkers = this.props.locations.map(location => {
			const ciarka = location.gps.indexOf(',');
			const lat = location.gps.substring(0, ciarka);
			const long = location.gps.substring(ciarka + 1);
			return <Marker
				location={location}
				key={location._id}
				name={location.name}
				position={{ lat: lat, lng: long }}
				onClick={this.onMarkerClick}
				icon={img_marker}
			></Marker>
		})
		let selectedPlaceName = ''
		if (this.state.selectedPlace.name)
			selectedPlaceName = this.state.selectedPlace.name[this.props.lang.toUpperCase()];
		return (
			<div className='container' style={{ minHeight: 650 }}>
				<div style={mapStyle}>
					<img src={icon} alt=''></img>
					<span style={title1Style}>{lang[this.props.lang].main.map}</span>
					{/* <img src={line1} alt='' style={line1Style}></img> */}
					<div className='row text-center'>
						<div className='col-12' style={{ padding: '0.6rem' }} >
							<hr style={line1Style}></hr>
						</div>
					</div>
					<Map
						google={this.props.google}
						initialCenter={{
							lat: 48.997906,
							lng: 21.239607
						}}
						zoom={12}
						onClick={this.onMapClicked}
						style={{ height: '90%' }}
					>
						{mapMarkers}

						<InfoWindow
							marker={this.state.activeMarker}
							visible={this.state.showingInfoWindow}
							onClose={this.onInfoWindowClose}
						//onOpen={this.onInfoWindowOpen}
						>
							<div id="info">
								<button style={infoBtnStyle}></button>
								<div style={{ textAlign: 'center' }}>
									<img id='infoImg' alt='' style={{ marginTop: '1rem' }} src={this.state.photoImg}></img>
									<h1 style={locTitleStyle}>{selectedPlaceName}</h1>
								</div>
							</div>
						</InfoWindow>
					</Map>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	lang: state.main.lang,
	locations: state.loc.locations,
	photos: state.loc.photos
});

export default connect(mapStateToProps, { fetchLocations, setLocation, fetchMapPhotos })(
	GoogleApiWrapper({
		apiKey: 'AIzaSyB39IxpU2bLZyldnazddyOqQz3DH1Ietms'
	})(MapContainer)
)
import { FETCH_CALENDAR, FETCH_HOLIDAYS, GET_CALENDAR } from '../actions/types';

const initialState = {
	calendar: [],
	holidays: [],
	item: {}
}

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_CALENDAR:
			return {
				...state,
				calendar: action.payload
			}
		case FETCH_HOLIDAYS:
			return {
				...state,
				holidays: action.payload
			}
		case GET_CALENDAR:
			return {
				...state,
				item: action.payload
			}
		default:
			return state;
	}
}
import { FETCH_LOCATIONS, GET_LOCATION, GET_MAP_PHOTO, URL } from './types';

export const fetchLocations = () => dispatch => {
	fetch(URL + '/api/locations')
		.then(res => res.json())
		.then(data => dispatch({
			type: FETCH_LOCATIONS,
			payload: data.data
		}));
}

export const fetchMapPhotos = () => dispatch => {
	fetch(URL + '/api/photoMap')
		.then(res => res.json())
		.then(data => dispatch({
			type: GET_MAP_PHOTO,
			payload: data.data
		}));
}

export const setLocation = (loc) => dispatch => {
	dispatch({
		type: GET_LOCATION,
		payload: loc
	})
}
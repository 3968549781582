import { FETCH_CALENDAR, FETCH_HOLIDAYS, GET_CALENDAR, URL } from './types';

export const fetchCalendar = (month, year) => dispatch => {
	fetch(URL + '/api/calendar?month=' + month + '&year=' + year)
		.then(res => res.json())
		.then(data => dispatch({
			type: FETCH_CALENDAR,
			payload: data.data
		}));
}

export const fetchHolidays = (month, year) => dispatch => {
	fetch(URL + '/api/calendarHolidays?month=' + month + '&year=' + year)
		.then(res => res.json())
		.then(data => dispatch({
			type: FETCH_HOLIDAYS,
			payload: data.data
		}));
}

export const setCalendar = (cal) => dispatch => {
	dispatch({
		type: GET_CALENDAR,
		payload: cal
	})
}
import React, { Component } from 'react'
import { PropTypes } from 'prop-types';
import lang from '../../lang';
import { connect } from 'react-redux';
import { fetchLocations } from '../../actions/locActions';

import LocationItem from '../LocationItem';

import icon from '../../images/locations/Ikona01.png';

class Locations extends Component {
	componentWillMount() {
		this.props.fetchLocations();
	}
	render() {
		const locItems = this.props.locations.map(location => (
			<LocationItem key={location._id} location={location} history={this.props.history}></LocationItem>
		))
		const title1Style = {
			fontSize: '16px',
			fontWeight: '700',
			color: '#9E978A',
			marginLeft: '30px',
			position: 'relative',
			top: '0.2rem'
		}
		const line1Style = {
			transform: 'translateX(-50%)',
			marginLeft: '50%',
			marginTop: '-0.5rem',
			borderColor: '#EDEBE8',
			borderWidth: '2px',
			width: '100%',
			maxWidth: 1054,
			borderRadius: 2,
		}
		return (
			<div style={locStyle}>
				<img src={icon} alt=''></img>
				<span style={title1Style}>{lang[this.props.lang].locations.title}</span>
				<div className='row text-center'>
					<div className='col-12' style={{ padding: '0.6rem' }} >
						<hr style={line1Style}></hr>
					</div>
				</div>
				{locItems}
			</div>
		)
	}
}

Locations.propTypes = {
	lang: PropTypes.string.isRequired,
	locations: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
	lang: state.main.lang,
	locations: state.loc.locations
});

const locStyle = {
	textAlign: 'left',
	paddingBottom: 30
}

export default connect(mapStateToProps, { fetchLocations })(Locations)
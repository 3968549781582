import { CHANGE_LANG } from './types';
import { CHANGE_TAB } from './types';
import { CHANGE_MODAL } from './types';

export const changeLang = (lang) => dispatch => {
	dispatch({
		type: CHANGE_LANG,
		payload: lang
	});
}

export const changeTab = (tab) => dispatch => {
	dispatch({
		type: CHANGE_TAB,
		payload: tab
	});
}

export const changeModal = (modal) => dispatch => {
	dispatch({
		type: CHANGE_MODAL,
		payload: modal
	});
}
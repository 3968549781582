import React, { Component } from 'react';
import PropTypes from 'prop-types';

import kruzok from '../images/calendar/kruzok.png';

class Day extends Component {
	render() {
		const {
			today,
			date,
			selected,
			classes,
			handleClick,
			children,
		} = this.props;

		let kruzokStyle = {
			transform: 'translate(-50%,-50%)',
			left: '80%',
			top: '20%',
			width: '6px',
			height: '6px',
			backgroundColor: '#FFFFFF',
			borderRadius: '6px',
			border: 'none',
			outline: 'none',
			position: 'absolute',
			display: !this.props.isEvents ? 'none' : 'inline-block',
		}

		const textStyle = {
			transform: 'translate(-50%,-50%)',
			left: '52%',
			top: '45%',
			position: 'absolute'
		}

		const classNames = ['Day'];
		if (today.isSame(date, 'day')) {
			classNames.push('today');
			kruzokStyle.backgroundColor = '#FFFFFF';
		}
		else if (classes.includes('normal')) {
			kruzokStyle.backgroundColor = '#69645C';
		}
		else if (classes.includes('holidays')) {
			kruzokStyle.backgroundColor = '#2B8B7E';
		}
		else if (classes.includes('weekend')) {
			kruzokStyle.backgroundColor = '#484D91';
		}
		if (selected && selected.isSame(date, 'day')) {
			kruzokStyle.backgroundColor = '#FFFFFF';
			if (classNames.includes('today')) {
				classNames.push('selected-today');
			}
			else if (classes.includes('holidays')) {
				classNames.push('selected-holidays');
			}
			else if (classes.includes('weekend')) {
				classNames.push('selected-weekend');
			}
			else
				classNames.push('selected');
		}

		let body;
		if (!!children) {
			body = children;
		} else {
			body = (
				<div style={{ position: 'relative' }}>
					<button
						className={[...classNames, ...classes].join(' ')}
						onClick={() => handleClick(date)}
						type="button"
						style={{ outline: 'none' }}
					>
						<span style={kruzokStyle}>
						</span>
						<span style={textStyle}>{date.format('D')}</span>

					</button>

				</div>
			);
		}

		return (
			<td
				// className={[...classNames, ...classes].join(' ')}
				data-date={date.toISOString()}
				data-day={date.format('D')}
			>
				{body}
			</td>
		);
	}
}

Day.propTypes = {
	handleClick: PropTypes.func.isRequired,
	date: PropTypes.object.isRequired,
	today: PropTypes.object.isRequired,
	selected: PropTypes.object,
	children: PropTypes.node,
	isEvents: PropTypes.bool
};

export default Day;

import { CHANGE_LANG } from '../actions/types';
import { CHANGE_TAB } from '../actions/types';
import { CHANGE_MODAL } from '../actions/types';

const initialState = {
	lang: 'sk',
	tab: 0,
	showModal: true
}

export default function (state = initialState, action) {
	switch (action.type) {
		case CHANGE_LANG:
			return {
				...state,
				lang: action.payload
			}
		case CHANGE_TAB:
			return {
				...state,
				tab: action.payload
			}
		case CHANGE_MODAL:
			return {
				...state,
				showModal: action.payload
			}
		default:
			return state;
	}
}
import React, { Component } from 'react'
import lang from '../lang';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { setLocation } from '../actions/locActions';

import img_icon_sipka from '../images/locations/ikonSipocka.png';

class LocationItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	openInfo = () => {
		this.props.setLocation(this.props.location);
		this.props.history.push("lokacie/info");
	}
	openGallery = () => {
		this.props.setLocation(this.props.location);
		this.props.history.push("galeria");
	}
	render() {
		const locItemStyle = {
			backgroundColor: '#ecebe8',
			borderRadius: '15px',
			width: '100%',
			maxWidth: '800px',
			height: '40px',
			position: 'relative',
			//marginTop: '10px',
			transform: 'translateX(-50%)',
			marginLeft: '50%'
		}
		const locTileStyle = {
			fontSize: '17px',
			color: '#9E978AFF',
			position: 'absolute',
			transform: 'translateY(-50%)',
			top: '50%',
		}
		const locSipkaStyle = {
			//marginLeft: '600px',
			//marginTop: '-60px'
		}
		return (
			<div style={{ position: 'relative' }}>
				<div className='row text-center'>
					<div className='col-12' style={{ padding: '0.5rem' }}>
						<div style={locItemStyle}>
							<div className='row text-left'>
								<div className='col-1 col-md-2 col-lg-2'></div>
								<div className='col-9 col-md-7 col-lg-7' style={{ padding: '0.6rem' }}>
									<h3 className='loc-text' style={locTileStyle}>{this.props.location.name[this.props.lang.toUpperCase()]}</h3>
								</div>
								<div className='col-2 col-md-3' style={{ padding: '0.5rem' }}>
									<img src={img_icon_sipka} alt="sipka" style={locSipkaStyle}></img>
								</div>
							</div>
						</div>
						<button onClick={this.openInfo} className='loc-item'></button>
					</div>
				</div>
			</div>
		)
	}
}

LocationItem.propTypes = {
	lang: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	lang: state.main.lang
});


export default connect(mapStateToProps, { setLocation })(LocationItem)
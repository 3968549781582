export default {
	en: {
		main: {
			home: 'HOME',
			map: 'MAP',
			loc: 'LIST OF LOCATIONS',
			cal: 'CALENDAR',
			title: 'Home Page',
			text: 'At the beginning of 2017, a bronze 3D model was added in the center of Prešov, in front of the Šariš Gallery. It depicts the historical appearance of the center of Prešov from the 18th century, including the walls, entrance gates to the city, or extinct buildings. The model features significant landmarks of Prešov and its surroundings. '
				+ '<br> <br> <b> Discover them with dreams. Visit the most important tourist sites in Presov and its surroundings. </b> Learn about their history, view photos, or upload your own photos to the gallery. You can also take part in the competition and get interesting prizes. ',
			text2: 'This site was created as part of the project „A look into the past - the historical and cultural heritage of Krosno and Prešov“, which is funded by the European Union from the European Regional Development Fund',
			eu: 'Interreg project ...:',
			mesto: 'Mesto Prešov',
			download: 'Download app Dedičstvo Prešova',
			android: 'Download for Android: <br /> Minimum Requirements Android 4.3',
			ios: 'Download for iOS: <br /> Minimum Requirements iOS 10.3',
			downloadBtn: 'Download',
			close: 'Close'
		},
		locations: {
			title: 'Locations',
			gallery: 'gallery',
			zoom: 'zoom text',
			audio: 'audio guide'
		},
		calendar: {
			title: 'Calendar',
			months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
			days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
			today: ' / today'
		}
	},
	sk: {
		main: {
			home: 'DOMOV',
			map: 'MAPA',
			loc: 'LIST LOKÁCII',
			cal: 'KALENDÁR',
			title: 'Titulka',
			text: 'Začiatkom roku 2017 pribudol v centre mesta Prešov, pred Šarišskou galériou bronzový 3D model. Stvárňuje historickú podobu jadra Prešova z 18. storočia vrátane hradieb, vstupných brán do mesta, či zaniknutých budov. Súčasťou modelu sú významné dominanty Prešova a blízkeho okolia.'
				+ '<br><br><b>Objavujte ich spolu snami. Navštívte najvýznamnejšie turistické miesta v Prešove a blízkom okolí.</b> Dozviete sa o ich histórii, môžete si pozrieť fotografie, či nahrať svoje vlastné fotografie do galérie. Môžete sa aj zapojiť do súťaže a získať zaujímavé ceny.',
			text2: 'Táto stránka vznikla v rámci projektu „Pohľad do minulosti - historické a kultúrne dedičstvo Krosna a Prešova“, ktorý je realizovaný s finančnou podporou Európskej únie z Európskeho fondu regionálneho rozvoja',
			eu: 'Projekt Interreg... :',
			mesto: 'Mesto Prešov',
			download: 'Stiahnúť aplikáciu Dedičstvo Prešova',
			android: 'Stiahnut pre Android :<br /> Minimálne požiadavky Android 4.3',
			ios: 'Stiahnut pre iOS :<br /> Minimálne poziadavky iOS 10.3',
			downloadBtn: 'Stiahnúť',
			close: 'Zavrieť'
		},
		locations: {
			title: 'Lokácie',
			gallery: 'foto galéria',
			zoom: 'zväčšiť text',
			audio: 'audio sprievodca'
		},
		calendar: {
			title: 'Kalendár',
			months: ["Január", "Február", "Marec", "Apríl", "Máj", "Jún", "Júl", "August", "September", "Október", "November", "December"],
			days: ["Pondelok", "Utorok", "Streda", "Štvrtok", "Piatok", "Sobota", "Nedeľa"],
			today: ' / dnes'
		}
	},
	pl: {
		main: {
			home: 'DOM',
			map: 'MAPA',
			loc: 'LOKALIZACJA ARKUSZU',
			cal: 'KALENDARZ',
			title: 'Strona główna',
			text: 'Na początku 2017 r.w centrum Preszowa, przed galerią Šariš, dodano brązowy model 3D. Przedstawia historyczny wygląd centrum Preszowa z XVIII wieku, w tym mury, bramy wjazdowe do miasta lub wymarłe budynki. Model zawiera znaczące zabytki Preszowa i okolic.'
				+ '<br> <br> <b> Odkryj je ze snami. Odwiedź najważniejsze miejsca turystyczne w Preszowie i okolicach. </b> Poznaj ich historię, przeglądaj zdjęcia lub przesyłaj własne zdjęcia do galerii. Możesz także wziąć udział w konkursie i zdobyć ciekawe nagrody.',
			text2: 'Ta strona powstała w ramach projektu „Spojrzenie w przeszłość - dziedzictwo historyczne i kulturowe Krosna i Preszowa ”, finansowanego przez Unię Europejską z Europejskiego Funduszu Rozwoju Regionalnego',
			eu: 'Projekt Interreg...:',
			mesto: 'Mesto Prešov',
			download: 'Pobierz aplikację Dedičstvo Prešova',
			android: 'Pobierz na Androida: <br /> Minimalne wymagania Android 4.3',
			ios: 'Pobierz na iOS: <br /> Minimalne wymagania iOS 10.3',
			downloadBtn: 'Pobierz',
			close: 'Zamknąć'
		},
		locations: {
			title: 'Lokalizacje',
			gallery: 'galeria zdjęć',
			zoom: 'powiększ tekst',
			audio: 'przewodnik audio'
		},
		calendar: {
			title: 'Kalendarz',
			months: ["Styczeń", "Luty", "Marec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
			days: ["Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota", "Niedziela"],
			today: ' / dzisiaj'
		}
	}
}
import { FETCH_LOCATIONS, GET_LOCATION, GET_MAP_PHOTO } from '../actions/types';

const initialState = {
	locations: [],
	location: {},
	photos: []
}

function changeHyperLink(text_object) {
	let result = {};
	Object.entries(text_object).forEach(entry => {
		let text = entry[1];
		const splitIndexArray = getIndicesOf("<link=", text)
		if (splitIndexArray.length === 0) {
			result[entry[0]] = text;
		}
		else {
			const string1 = text.substring(0, splitIndexArray[0]);
			let result_string = string1;
			for (let i = 0; i < splitIndexArray.length; i++) {
				const splitIndex = splitIndexArray[i];
				let lastIndex = text.length;
				if (i + 1 < splitIndexArray.length)
					lastIndex = splitIndexArray[i + 1];
				const string2 = text.substring(splitIndex, lastIndex);
				const url = string2.substring(
					string2.indexOf('"') + 1,
					string2.lastIndexOf('"')
				);
				result_string += '<br><a href="' + url + '">' + url + '</a>';
			}
			result[entry[0]] = result_string;
		}
	});
	return result;
}

function getIndicesOf(searchStr, str, caseSensitive) {
	var searchStrLen = searchStr.length;
	if (searchStrLen === 0) {
		return [];
	}
	var startIndex = 0, index, indices = [];
	if (!caseSensitive) {
		str = str.toLowerCase();
		searchStr = searchStr.toLowerCase();
	}
	while ((index = str.indexOf(searchStr, startIndex)) > -1) {
		indices.push(index);
		startIndex = index + searchStrLen;
	}
	return indices;
}

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_LOCATIONS:
			for (let i = 0; i < action.payload.length; i++) {
				action.payload[i].description = changeHyperLink(action.payload[i].description);
			}
			return {
				...state,
				locations: action.payload
			}
		case GET_LOCATION:
			return {
				...state,
				location: action.payload
			}
		case GET_MAP_PHOTO:
			return {
				...state,
				photos: action.payload
			}
		default:
			return state;
	}
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment/locale/sk'
import Day from './Day';
import DayOfWeek from './DayOfWeek';
import Week from './Week';

import img_sipka from '../images/calendar/calendar09.png';
import img_line from '../images/main/line04.png';

class CalendarItem extends Component {
	constructor(props) {
		super(props);
		let date = props.date;
		let month;
		if (date) {
			month = props.date;
		} else {
			month = props.month;
		}
		this.state = {
			date: date,
			month: month,
		};

		this.previous = this.previous.bind(this);
		this.next = this.next.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	componentWillMount() {
		moment.locale(this.props.locale);
		if (!!this.state.date) {
			this.state.date.locale(this.props.locale);
		}
		this.state.month.locale(this.props.locale);
		this.props.updateCalendar(this.state.month.month(), this.state.month.year());
		this.handleClick(this.state.month);
	}

	componentWillUpdate(nextProps, nextState) {
		moment.locale(this.props.locale);
		if (!!nextState.date) {
			nextState.date.locale(this.props.locale);
		}
		nextState.month.locale(this.props.locale);
	}

	handleClick(date) {
		const flag = this.props.onSelect(date, this.state.date, this.state.month);

		if (flag === true) {
			this.setState({
				date: moment(date),
			});
		} else if (flag === false) {
			this.setState({
				date: null,
			});
		}
	}

	previous() {
		const prev = moment(this.state.month).subtract(1, 'month')
		this.setState({
			month: prev,
		});
		this.props.updateCalendar(prev.month(), prev.year())
	}

	next() {
		const next = moment(this.state.month).add(1, 'month')
		this.setState({
			month: next,
		});
		this.props.updateCalendar(next.month(), next.year())
	}

	render() {
		const { startOfWeekIndex, dayRenderer, dayOfWeekFormat } = this.props;
		const classes = ['Calendar', this.props.className].join(' ');
		const today = moment();
		const format = dayOfWeekFormat &&
			dayOfWeekFormat !== '' &&
			moment(today, dayOfWeekFormat).isValid() ? dayOfWeekFormat : 'dd'
		const date = this.state.date;
		const month = this.state.month;
		const current = month
			.clone()
			.startOf('month')
			.day(startOfWeekIndex);
		if (current.date() > 1 && current.date() < 7) {
			current.subtract(7, 'd');
		}
		const end = month
			.clone()
			.endOf('month')
			.day(7 + startOfWeekIndex);

		if (end.date() > 7) {
			end.subtract(7, 'd');
		}
		const elements = [];
		let days = [];
		let week = 1;
		let i = 1;
		const daysOfWeek = [];
		const day = current.clone();
		for (let j = 0; j < 7; j++) {
			const dayOfWeekKey = 'dayOfWeek' + j;
			daysOfWeek.push(<DayOfWeek key={dayOfWeekKey} date={day.clone()} format={format} />);
			day.add(1, 'days');
		}
		while (current.isBefore(end)) {
			let dayClasses = this.props.dayClasses(current);
			//console.log(dayClasses);
			if (!current.isSame(month, 'month')) {
				dayClasses = ['other-month'];
			}
			let props = {
				date: current.clone(),
				selected: date,
				month: month,
				today: today,
				classes: dayClasses,
				handleClick: this.handleClick,
				isEvents: this.props.isEvents(current.clone()),
			};

			let children;
			if (!!dayRenderer) {
				children = dayRenderer(props);
			}

			days.push(
				<Day key={i++} {...props}>
					{children}
				</Day>
			);
			current.add(1, 'days');
			if (current.day() === startOfWeekIndex) {
				let weekKey = 'week' + week++;
				elements.push(<Week key={weekKey}>{days}</Week>);
				days = [];
			}
		}

		const monthStyle = {
			backgroundColor: '#7980CC',
			width: '154px',
			height: '30px',
			borderRadius: '10px',
			color: '#fff',
			transform: 'translateX(-50%)',
			marginLeft: '50%',
			paddingTop: 2
		}
		const sipkaLStyle = {
			backgroundImage: `url(${img_sipka})`,
			width: 32,
			height: 32,
			padding: 0
		}
		const sipkaRStyle = {
			backgroundImage: `url(${img_sipka})`,
			width: 32,
			height: 32,
			transform: 'scaleX(-1)',
			padding: 0
		}

		const nav = (
			<tr className="month-header">
				<th>
					<button className="btn img-btn nav-inner" onClick={this.previous} type="button" style={sipkaLStyle}>
					</button>
				</th>
				<th colSpan="5" >
					<div style={monthStyle}>
						<span className="month">{month.format('MMMM').toUpperCase()}</span>{' / '}
						<span className="year">{month.format('YY')}</span>
					</div>
				</th>
				<th>
					<button className="btn img-btn nav-inner" onClick={this.next} type="button" style={sipkaRStyle}>
					</button>
				</th>
			</tr>

		);

		return (
			<table className={classes} style={{ transform: 'translateX(-50%)', marginLeft: '50%', marginTop: '-1rem' }}>
				<thead>{nav}</thead>
				<thead>
					<tr>
						<th colSpan="7">
							<img src={img_line} alt='' style={{ marginTop: '-10px' }}></img>
						</th>
					</tr>
				</thead>
				<thead>
					<tr className="days-header">{daysOfWeek}</tr>
				</thead>
				<tbody>{elements}</tbody>
			</table>
		);
	}
}
CalendarItem.defaultProps = {
	month: moment(),
	dayClasses: () => [],
	useNav: true,
	locale: 'en',
	startOfWeekIndex: 0,
	dayOfWeekFormat: 'dd',
};
CalendarItem.propTypes = {
	onSelect: PropTypes.func.isRequired,
	updateCalendar: PropTypes.func.isRequired,
	date: PropTypes.object,
	month: PropTypes.object,
	dayClasses: PropTypes.func,
	useNav: PropTypes.bool,
	locale: PropTypes.string,
	startOfWeekIndex: PropTypes.number,
	dayRenderer: PropTypes.func,
	dayOfWeekFormat: PropTypes.string,
	isEvents: PropTypes.func.isRequired,
};

export default CalendarItem;

import React, { Component } from 'react';
import resize from '../../resize';

import bg1 from '../../images/bg/bg1.png';
import bg2 from '../../images/bg/bg2.png';
import bg3 from '../../images/bg/bg3.png';
import erb from '../../images/bg/erb.png';
import roh from '../../images/bg/roh.png';
import logo from '../../images/main/logo.png';

export default class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			windowWidth: 0
		}
	}
	backgrounds = [bg1, bg2, bg3]
	bg = bg1
	componentWillMount() {
		const random = Math.floor(Math.random() * 3);
		this.bg = this.backgrounds[random];
	}
	render() {
		const erbStyle = {
			position: 'absolute',
			top: 0,
			left: '100%',
			transform: 'translateX(-100%)'
		}
		const rohLStyle = {
			position: 'absolute',
			left: 0,
			top: '105px',
			transform: 'scaleX(-1)',
			marginLeft: '-6px'
		}
		const rohRStyle = {
			position: 'absolute',
			left: '100%',
			top: '105px',
			transform: 'translateX(-100%)',
			marginLeft: '6px'
		}
		const rohDivStyle = {
			transform: 'translate(-50%, 0%)',
			top: 0,
			left: '50%',
			position: 'absolute',
			width: '100%',
			maxWidth: '1280px',
		}
		const erbDivStyle = {
			position: 'absolute',
			left: '52.5%',
			transform: 'translate(-50%, 0%)',
			top: 1,
			width: '100%',
			maxWidth: '1280px',
		}
		const logoStyle = {
			position: 'absolute',
			top: 50,
			left: '80%',
			transform: 'translateX(-100%)'
		}
		return (
			<div style={headerStyle} id="header">
				<img src={this.bg} alt="bg" id="bg" style={bgStyle}></img>
				<div style={rohDivStyle}>
					<img src={roh} alt="roh" style={rohLStyle}></img>
					<img src={roh} alt="roh" style={rohRStyle}></img>
				</div>
				<div className='row text-right' style={erbDivStyle}>
					<div className='col-11' id='erb-col'>
						<img src={logo} alt="logo" id="logo" style={logoStyle}></img>
						<img src={erb} alt="erb" id="erb" style={erbStyle}></img>
					</div>
				</div>
			</div>
		)
	}
}

const headerStyle = {
	position: 'relative',
	top: 0,
	left: 0
}

const bgStyle = {
	position: 'relative',
	objectFit: 'cover',
}

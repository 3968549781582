export const CHANGE_LANG = 'CHANGE_LANG';
export const CHANGE_TAB = 'CHANGE_TAB';
export const CHANGE_MODAL = 'CHANGE_MODAL';
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const GET_LOCATION = 'GET_LOCATION';
export const FETCH_CALENDAR = 'FETCH_CALENDAR';
export const FETCH_HOLIDAYS = 'FETCH_HOLIDAYS';
export const GET_CALENDAR = 'GET_CALENDAR';
export const FETCH_GALLERY = 'FETCH_GALLERY';
export const GET_PHOTO = 'GET_PHOTO';
export const GET_MAP_PHOTO = 'GET_MAP_PHOTO';
export const URL = 'https://presov.functugames.com';
//export const URL = 'http://192.168.1.193:3000';
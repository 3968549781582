import React, { Component } from 'react';
import resize from '../../resize';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import lang from '../../lang';
import { changeLang } from '../../actions/mainActions';

import img_sk from '../../images/main/vl1.png';
import img_pl from '../../images/main/vl2.png';
import img_en from '../../images/main/vl3.png';

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			opacitySk: 1,
			opacityEn: 0.4,
			opacityPL: 0.4
		}
	}
	changeLanguage = (lang) => {
		this.props.changeLang(lang);
		if (lang === 'en') {
			this.setState({ opacityEn: 1, opacitySk: 0.4, opacityPL: 0.4 });
		} else if (lang === 'sk') {
			this.setState({ opacityEn: 0.4, opacitySk: 1, opacityPL: 0.4 });
		} else if (lang === 'pl') {
			this.setState({ opacityEn: 0.4, opacitySk: 0.4, opacityPL: 1 });
		}
	}
	render() {
		const footerStyle = {
			backgroundColor: '#7c776c',
			color: '#3c3935',
			width: '100%',
			maxWidth: 1280,
			height: 44,
			transform: 'translateX(-50%)',
			left: '50%',
			borderRadius: '16px 16px 0 0',
			position: 'absolute',
			bottom: 0
		}
		const skBtnStyle = {
			marginRight: '8px',
			opacity: this.state.opacitySk,
			padding: 0
		}
		const plBtnStyle = {
			marginRight: '8px',
			opacity: this.state.opacityPL,
			padding: 0
		}
		const enBtnStyle = {
			opacity: this.state.opacityEn,
			padding: 0
		}
		return (
			<footer style={footerStyle}>
				<div className='container'>
					<div className='row text-center'>
						<div className='col-4 col-sm-3 footer-col' style={{ padding: '0.5rem' }}>
							<div>
								<button className="btn img-btn footer-flag" style={skBtnStyle} type="button" onClick={() => this.changeLanguage('sk')}>
									<img src={img_sk} alt=''></img>
								</button>
								<button className="btn img-btn footer-flag" style={plBtnStyle} type="button" onClick={() => this.changeLanguage('pl')}>
									<img src={img_pl} alt=''></img>
								</button>
								<button className="btn img-btn footer-flag" style={enBtnStyle} type="button" onClick={() => this.changeLanguage('en')}>
									<img src={img_en} alt=''></img>
								</button>
							</div>
						</div>
						<div id='footer-text' className='col-8 col-sm-9 footer-col' style={{ padding: '0.5rem', lineHeight: 1 }}>
							<div style={{ position: "absolute", transform: 'translateY(-50%)', top: '50%' }}>Mesto Presov, Hlavna 2907/73, 080 01 Presov, tel.:051/3100 101, Email: webmaster@presov.sk</div>
						</div>
					</div>
				</div>

			</footer>
		)
	}
}

Footer.propTypes = {
	changeLang: PropTypes.func.isRequired,
	lang: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	lang: state.main.lang
});

export default connect(mapStateToProps, { changeLang })(Footer);